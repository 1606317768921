@import "node_modules/animate.css/animate";

@import "node_modules/flag-icon-css/css/flag-icon";

@import "node_modules/bootstrap/scss/bootstrap";

@import "vendor/font-awesome-5/fontawesome";

@import "node_modules/aos/dist/aos";

.was-validated .input-group:invalid ~ .invalid-feedback, .was-validated .input-group:invalid ~ .invalid-tooltip,
.input-group.is-invalid ~ .invalid-feedback, .input-group.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.cke.is-invalid {
  border: 1px solid $danger;
}